import React from 'react';

const Terms = () => {
	return (
		<div className='privacy'>
			<h1>Messaging Terms & Conditions</h1>
			<p>
				You agree to receive informational messages (appointment reminders,
				account notifications, etc.) from Eco-Ducts. Message frequency varies.
				Message and data rates may apply. For help, reply HELP to text.
				<br /> <br />
				You can opt out at any time by replying STOP.
			</p>
		</div>
	);
};

export default Terms;
