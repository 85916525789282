import React from 'react';

const PrivacyPolicy = () => {
	return (
		<div className='privacy'>
			<h1>Privacy and Policy</h1>
			<p>
				You agree to receive informational messages (appointment reminders,
				account notifications, etc.) from Eco-Ducts. Message frequency varies.
				Message and data rates may apply. For help, reply HELP or email us at{' '}
				<a href='mailto:ecod24@gmail.com'>Ecod24@gmail.com</a>. You can opt out
				at any time by replying STOP."
			</p>
		</div>
	);
};

export default PrivacyPolicy;
