import './index.css';
import { Routes, Route, Link } from 'react-router';
import Home from './pages/Home';
import Header from './components/Header';
import Container from './components/Container';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
	return (
		<div>
			<Header></Header>

			<Container>
				<Routes>
					<Route
						path='/'
						element={<Home />}
					/>
					<Route
						path='/terms'
						element={<Terms />}
					/>
					<Route
						path='/privacy-policy'
						element={<PrivacyPolicy />}
					/>
				</Routes>
			</Container>
			<footer>
				<div style={{ display: 'flex', marginBottom: '12px', gap: '12px' }}>
					<Link
						className='link'
						to='/privacy-policy'
					>
						Privacy Policy
					</Link>
					<Link
						className='link'
						to='/terms'
					>
						Terms and Conditions
					</Link>
				</div>
				<div style={{ display: 'block' }}>
					Eco Ducts &copy; {new Date().getFullYear()}
				</div>
			</footer>
		</div>
	);
}

export default App;
